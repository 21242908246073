import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";

import styles from "./style.module.scss";

type OuterProps = {
  id: number;
  text: string;
  type?: "large" | undefined;
};

export const TagLink: React.FC<OuterProps> = (props) => {
  const { id, text, type } = props;
  return (
    <div key={id} className={styles.tagBox}>
      <Link
        to={`/tags/${kebabCase(text)}/`}
        className={type === "large" ? styles.tagLinkLarge : styles.tagLink}>
        {text}
      </Link>
    </div>
  );
};
