import React from "react";
import styles from "./style.module.css";

export function Amalink({ bookData }) {
  if (Array.isArray(bookData.detail) === false) {
    return null;
  } else {
    return (
      <div className={styles.amaBookComponent}>
        <a
          className={styles.amaBookLink}
          target="_blank"
          href={bookData.href}
          rel="noopener">
          <div className={styles.amaBookArea}>
            <img
              style={{ marginBottom: "0px" }}
              src={bookData.img}
              alt={bookData.title}
            />
            <img
              src={bookData.bcon}
              width="1"
              height="1"
              alt=""
              style={{ border: "none !important", margin: "0px !important" }}
            />
            <div className={styles.amaBookInfo}>
              <div className={styles.amaBookTitle}>
                <span>{bookData.title}</span>
              </div>
              <div className={styles.amaBookSub}>
                {bookData.detail.map((value, id) => (
                  <span key={id}>
                    {value}
                    <br />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
