import React from "react";
import { Link } from "gatsby";

import { Bio } from "../bio";
import { AdsenseComponent } from "../../02_molecules/adsense";
import foldestsc from "../../../pages/lp/foldest/sc-list.png";
import mdIcon from "../../../pages/lp/mdediter/icon.png";
import notyIcon from "../../../pages/lp/noty/icon.png";

import styles from "./style.module.scss";

export const Sidebar = () => (
  <div className={styles.subContents}>
    <div className={styles.subContentsTitle}>works</div>
    <div className={styles.lp}>
      <Link className={styles.lpContent} to={`/lp/mdediter/`}>
        <img src={mdIcon} alt="sleleton md editer" width="100px" />
        <h3 className={styles.appTitle}>skeleton md editer</h3>
        <div className={styles.appSubTitle}>マークダウン特化エディタアプリ</div>
        <div className={styles.appDiscription}>
          マークダウンのプレビュー・よく使う記号を入力するボタンが利用できます。
        </div>
      </Link>
      <Link className={styles.lpContent} to={`/lp/foldest/`}>
        <img src={foldestsc} alt="foldest note" width="100px" />
        <h3 className={styles.appTitle}>Foldest note</h3>
        <div className={styles.appSubTitle}>フォルダで管理するノートアプリ</div>
        <div className={styles.appDiscription}>
          シンプルな機能のノートアプリ、フォルダ階層を自由に作成して整理できます
        </div>
      </Link>
      <Link className={styles.lpContent} to={`/lp/noty/`}>
        <img src={notyIcon} alt="のっティ時刻表" width="100px" />
        <h3 className={styles.appTitle}>のっティ時刻表</h3>
        <div className={styles.appSubTitle}>
          野々市市コミュニティバス
          <br />
          のっティ時刻表アプリ
        </div>
        <div className={styles.appDiscription}>
          ルートとバス停を選択して時刻表が確認できます
        </div>
      </Link>
    </div>
    <Bio />
    <AdsenseComponent client="ca-pub-8068277400499023" slot="3466313310" />
  </div>
);
