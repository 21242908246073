import React from "react";
import { graphql } from "gatsby";
import Link from "gatsby-link";
import Helmet from "react-helmet";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";

import Layout from "../layout";
import { AdsenseComponent } from "../02_molecules/adsense";
import { Header } from "../03_organisms/header";
import { Footer } from "../03_organisms/footer";
import { Sidebar } from "../03_organisms/sidebar";
import { Amalink } from "../02_molecules/amalink";
import { Kindlelink } from "../02_molecules/kindle";
import { TagLink } from "../01_atoms/TagLink";

import ogPic from "../../images/ogp.png";
import styles from "./blog-post-style.module.scss";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const postUrl =
      this.props.data.site.siteMetadata.siteUrl + post.fields.slug;
    const siteMetaData = this.props.data.site.siteMetadata;
    const { previous, next } = this.props.pageContext;
    const postBookData = {
      title: post.frontmatter.bookTitle,
      href: post.frontmatter.bookHref,
      img: post.frontmatter.bookImg,
      detail: post.frontmatter.bookDetail,
      bcon: post.frontmatter.bookBcon,
    };
    const ogpimageUrl =
      post.frontmatter.ogpimage === "" || post.frontmatter.ogpimage === null
        ? ogPic
        : "/asset/ogp/" + post.frontmatter.ogpimage;
    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: "ja" }}
          title={post.frontmatter.title + " | " + siteMetaData.title}
          meta={[
            { name: "description", content: post.excerpt },
            {
              name: "keywords",
              content:
                post.frontmatter.title +
                "," +
                post.frontmatter.category +
                "," +
                post.frontmatter.tags.join(",") +
                siteMetaData.keywords,
            },
            {
              property: "og:title",
              content: post.frontmatter.title + " | " + siteMetaData.title,
            },
            { property: "og:description", content: post.excerpt },
            { property: "og:type", content: "website" },
            { property: "og:site_name", content: siteMetaData.title },
            { property: "og:url", content: postUrl },
            { property: "twitter:card", content: "summary_large_image" },
            { property: "twitter:site", content: siteMetaData.twitterUsername },
            {
              property: "google-site-verification",
              content: siteMetaData.google_site_verification,
            },
            {
              property: "og:image",
              content: siteMetaData.siteUrl + ogpimageUrl,
            },
          ]}>
          <script
            type="text/javascript"
            src="//b.st-hatena.com/js/bookmark_button.js"
            charSet="utf-8"
            async={true}
          />
          <script
            async
            src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        </Helmet>
        <div className="mainWrapper">
          <Header />
          <div id="main-contents">
            <div className={styles.addInfo}>本サイトではアフィリエイト広告を利用しています。</div>
            <div className={styles.blogPost}>
              <img
                width="900px"
                src={ogpimageUrl}
                className={styles.descItemImage}
                alt={post.frontmatter.title}
              />
              <span className={styles.descItemDate}>
                {post.frontmatter.date}
              </span>
              <h1 className={styles.descItemTitle}>{post.frontmatter.title}</h1>
              <div className={styles.descItemTags}>
                {post.frontmatter.tags.map((value, id) => (
                  <TagLink key={id} id={id} text={value} type={"large"} />
                ))}
              </div>
              <div
                className={styles.postMdHtml}
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              <Amalink bookData={postBookData} />
              {post.frontmatter.tags.indexOf("Kindle Unlimited") >= 0 && (
                <Kindlelink />
              )}
            </div>
            <ul style={{ marginTop: "1rem" }}>
              <li className={styles.snsButton}>
                <FacebookShareButton url={postUrl}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </li>
              <li className={styles.snsButton}>
                <LinkedinShareButton url={postUrl}>
                  <LinkedinIcon
                    title={post.frontmatter.title}
                    size={32}
                    round
                  />
                </LinkedinShareButton>
              </li>
              <li className={styles.snsButton}>
                <TwitterShareButton
                  title={post.frontmatter.title}
                  via="koshihonmushi"
                  url={postUrl}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </li>
              <li className={styles.snsButton}>
                <a
                  href="http://b.hatena.ne.jp/entry/"
                  className="hatena-bookmark-button"
                  data-hatena-bookmark-layout="vertical-normal"
                  data-hatena-bookmark-lang="ja"
                  title="このエントリーをはてなブックマークに追加">
                  <img
                    src="//b.st-hatena.com/images/entry-button/button-only@2x.png"
                    alt="このエントリーをはてなブックマークに追加"
                    width="20"
                    height="10"
                  />
                </a>
              </li>
            </ul>
            <AdsenseComponent
              client="ca-pub-8068277400499023"
              slot="1927879986"
            />
            <div className={styles.nextComponent}>
              {previous && (
                <div className={styles.nextDescArea}>
                  <div className={styles.nextComponentTitle}>prev</div>
                  <Link
                    to={previous.fields.slug}
                    className={styles.prevDesc}
                    rel="prev">
                    <span className={styles.nextDescDate}>
                      {previous.frontmatter.date}
                    </span>
                    <div className={styles.nextComponentImageArea}>
                      {previous.frontmatter.ogpimage === "" ||
                      previous.frontmatter.ogpimage === null ? (
                        <img
                          alt="前の記事OGP画像"
                          style={{ marginBottom: 0 }}
                          src={ogPic}
                        />
                      ) : (
                        <img
                          alt="前の記事OGP画像"
                          style={{ marginBottom: 0 }}
                          src={"/asset/ogp/" + previous.frontmatter.ogpimage}
                        />
                      )}
                    </div>
                    <h4 className={styles.nextDescTitle}>
                      {previous.frontmatter.title}
                    </h4>
                  </Link>
                </div>
              )}
              {next && (
                <div className={styles.nextDescArea}>
                  <div className={styles.nextComponentTitle}>next</div>
                  <Link
                    to={next.fields.slug}
                    className={styles.nextDesc}
                    rel="next">
                    <span className={styles.nextDescDate}>
                      {next.frontmatter.date}
                    </span>
                    <div className={styles.nextComponentImageArea}>
                      {next.frontmatter.ogpimage === "" ||
                      next.frontmatter.ogpimage === null ? (
                        <img
                          alt="次の記事OGP画像"
                          style={{ marginBottom: 0 }}
                          src={ogPic}
                        />
                      ) : (
                        <img
                          alt="次の記事OGP画像"
                          style={{ marginBottom: 0 }}
                          src={"/asset/ogp/" + next.frontmatter.ogpimage}
                        />
                      )}
                    </div>
                    <h4 className={styles.nextDescTitle}>
                      {next.frontmatter.title}
                    </h4>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <Sidebar />
          <Footer />
        </div>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        tags
        category
        bookTitle
        bookHref
        bookImg
        bookDetail
        bookBcon
        ogpimage
      }
      fields {
        slug
      }
      excerpt(truncate: true)
    }
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;
