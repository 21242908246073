import React from "react";
import AdSense from "react-adsense";

type OuterProps = {
  client: string;
  slot: string;
};

export const AdsenseComponent: React.FC<OuterProps> = (props: OuterProps) => {
  const { client, slot } = props;
  return (
    <AdSense.Google
      client={client}
      slot={slot}
      style={{ display: "block" }}
      data-full-width-responsive="true"
      format="auto"
    />
  );
};
