import React from "react";
import styles from "./style.module.css";

export function Kindlelink() {
  return (
    <div className={styles.kindleLink}>
      <a
        target="_blank"
        href="https://www.amazon.co.jp/kindle-dbs/hz/signup?tag=koshihonmushi-22">
        <h3 className="kindleLinkTitle">
          この本は<span className="kindleAccent"> Kindle Unlimited </span>
          で読めました！
        </h3>
        <div className="kindleLinkDetail">
          Kindle
          Unlimitedは色んな本が読み放題な、いわゆる「本のサブスクリプションサービス」です。
          <br />
          たくさん本を読みたい人、暇でやることない人、どんな本を読めばいいかわからない人にもおすすめです！
          <br />
          <span className="kindleLinkA">
            こちらから無料体験を始められます。
          </span>
        </div>
        <div className="kindleLinkSub">
          読みたい本がKindle Unlimitedで読めるか事前に確認しておきましょう。
        </div>
      </a>
    </div>
  );
}
